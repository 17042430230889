





























































































































































import { Aluno, AlunoTurma, Curso, Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { AlunoService, AlunoTurmaService, CursoService, TurmaService } from "@/core/services/geral";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class ListaInscricao extends PageBase{
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Turma', value: 'turma.codigo' },
        { text: 'Curso', value: 'turma.curso.nome' },
        { text: 'Aluno', value: 'aluno.nome' },
        { text: 'Valor', value: 'valorTotal', type:'number' },
        { text: 'Aprovado', value: 'aprovado', type:'bool' }
    ];
    item = new AlunoTurma();
    service = new AlunoTurmaService();

    dialogAprovacao: boolean = false;
    itemAprovacao: AlunoTurma = new AlunoTurma();

    headerParcelas: any[] = [
        { text: "Valor", value: "valor", sortable: false },
        { text: "Forma Pagamento", value: "formaPagamento.nome", sortable: false },
        { text: "Data Vencimento", value: "dataVencimento", sortable: false},
        { text: "Data Pagamento", value: "dataPagamento", sortable: false}
    ];

    sheet: boolean = false;
    filtro: any = {
        turmaId: null,
        cursoId: null,
        alunoId: null,
        situacao: null
    }

    onSearchAluno: any = null;
    isAlunoLoading: boolean = false;
    alunos: Aluno[] = [];
    alunoService: AlunoService = new AlunoService();

    onSearchCurso: any = null;
    isCursoLoading: boolean = false;
    cursos: Curso[] = [];
    cursoService: CursoService = new CursoService();

    onSearchTurma: any = null;
    isTurmaLoading: boolean = false;
    turmas: Turma[] = [];
    turmaService: TurmaService = new TurmaService();

    situacoes: any[] = [
        {id: null, nome: "TODAS"},
        {id: true, nome: "APROVADO"},
        {id: false, nome: "NÃO APROVADO"},
    ];

    itemClass(item: any){
        return item.aprovado ? 'aprovado' : 'nao-aprovado';
    }

    @Watch('onSearchAluno')
    SearchAluno (val:string){
        if(this.filtro.alunoId)
            return;
        if(this.isAlunoLoading)
            return;
        if(!val)
            return;

        this.isAlunoLoading = true
        this.alunoService.AutoComplete(val).then(
            res =>{
                this.alunos = res.data;
            },
            err => this.$swal('Aviso',err.response.data,'error')
        ).finally(() =>{
            this.isAlunoLoading = false;
        })
    }

    @Watch('onSearchCurso')
    SearchCurso (val:string){
        if(this.filtro.cursoId)
            return;
        if(this.isCursoLoading)
            return;
        if(!val)
            return;

        this.isCursoLoading = true
        this.cursoService.AutoComplete(val).then(
            res =>{
                this.cursos = res.data;
            },
            err => this.$swal('Aviso',err.response.data,'error')
        ).finally(() =>{
            this.isCursoLoading = false;
        })
    }
    
    @Watch('onSearchTurma')
    SearchTurma (val:string){
        if(this.filtro.turmaId)
            return;
        if(this.isTurmaLoading)
            return;
        if(!val)
            return;

        this.isTurmaLoading = true
        this.turmaService.AutoComplete(val, "Curso").then(
            res =>{
                this.turmas = res.data;
            },
            err => this.$swal('Aviso',err.response.data,'error')
        ).finally(() =>{
            this.isTurmaLoading = false;
        })
    }

    @Watch('filtro.turmaId')
    WatchTurmaFiltro(){
        if(this.filtro.turmaId > 0){
            this.cursos = [];
            this.filtro.cursoId = this.turmas.find(x => x.id == this.filtro.turmaId)!.cursoId;
            this.cursos.unshift(this.turmas.find(x => x.id == this.filtro.turmaId)!.curso);
        }
        else{
            return;
        }
    }

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;        
        
        this.loading = true;
        this.sheet = false;

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Turma.Curso, Aluno").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => {
                if (!err.response){
                    if(!navigator.onLine){
                        this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                    }
                    else{
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    }
                }
                else{
                    this.$swal("Aviso", err.response.data, "error");
                }
            }).finally(() => {
                this.loading = false;
            });
    }

    AbrirDialogCadastro(item?: AlunoTurma){
        if(item){
            this.service.ObterPorId(item.id,"Turma.Curso, Aluno, Turma.TipoHospedagem, Acomodacao, Parcelas.FormaPagamento, CashBacks").then(
                res=>{
                    this.item = new AlunoTurma(res.data);
                    this.dialogCadastro = true;
                },
                err => {
                    if (!err.response){
                        if(!navigator.onLine){
                            this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                        }
                        else{
                            this.$swal("Aviso", "Não foi possível acessar a API", "error");
                        }
                    }
                    else{
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            )
        }
        else{
            this.item = new AlunoTurma();
            this.item.usuarioId = this.app.usuarioId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: AlunoTurma){
        this.$swal({    
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.service.Excluir(item.id).then(
                    res => {
                        if (res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        if (err.response.status == 403){
                            this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                        }
                        else{
                            this.$swal('Aviso', err.response.data, 'error')
                        }
                    }
                )
                },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value){
                this.$swal("Aviso", result.value, "success");
                this.Atualizar();
            }
        })
    }

    GetAprovacao(item: AlunoTurma){
        this.service.ObterPorId(item.id, "Turma, Parcelas.FormaPagamento, CashBacks").then(
            res => {
                this.itemAprovacao = res.data;
                this.dialogAprovacao = true;
            },
            err => {
                if (err.response.status == 403){
                    this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                }
                else{
                    this.$swal('Aviso', err.response.data, 'error')
                }
            }
        )
    }

    Aprovar(item: AlunoTurma){
        this.service.Aprovar(item.id).then(
            res=> {
                this.$swal("Aviso", "Inscrição aprovada!", "success");
                this.itemAprovacao = new AlunoTurma();
                this.dialogAprovacao = false;
                this.Atualizar();
            },
            err => {
                if (err.response.status == 403){
                    this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                }
                else{
                    this.$swal('Aviso', err.response.data, 'error')
                }
            }
        )
    }
}
